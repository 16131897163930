import "./Footer.scss"

function Footer() {
    return (
        <div className="footer container">
            <footer className="footer__footer">
                <a className="footer__footer__element" href="/">Sirious</a>
                <a className="footer__footer__element" href="/explore">Explore</a>
                <a className="footer__footer__element" href="/">About</a>
            </footer>
        </div>
    );
}

export default Footer;