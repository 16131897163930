import "./ExoplanetDetail.scss"
import exoplanets from "../../data/exoplanets.json"
import { useSearchParams } from "react-router-dom";

function ExoplanetDetail() {
    const [searchParams, setSearchParams] = useSearchParams();
    const indexStr = searchParams.get("i");

    if (indexStr == null) {
        return (
            <h1>Not found</h1>
        );
    }

    const index = parseInt(indexStr);
    
    if (index >= exoplanets.length) {
        return (
            <h1>Not found</h1>
        );
    }
    const exoplanet = exoplanets[index];

    return (
        <div className="exoplanet-detail container">
            <h1 className="exoplanet-detail__title">{exoplanet.title}</h1>
            <p className="exoplanet-detail__description">{exoplanet.description}</p>
            <p className="exoplanet-detail__radius"><b>Radius:</b> {exoplanet.radius} x Earth</p>
            <p className="exoplanet-detail__mass"><b>Mass:</b> {exoplanet.mass} x Earth</p>
            <p className="exoplanet-detail__orbital"><b>Orbital period (years):</b> {exoplanet.orbital}</p>
            <p className="exoplanet-detail__detection"><b>Detection type:</b> {exoplanet.detection}</p>
            <p className="exoplanet-detail__habitability"><b>Habitability:</b> {exoplanet.habitability}</p>
            <p className="exoplanet-detail__star-distance"><b>Distance from the star (light years):</b> {exoplanet.starDistance}</p>
    </div>
    );
}

export default ExoplanetDetail;