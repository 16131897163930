import React from "react";
import * as ReactDOM from "react-dom/client"
import App from "./components/App/App";

import "./index.scss"

const container = document.getElementById("root")
if (container != null) {
    const root = ReactDOM.createRoot(container);
    root.render(<App/>);
}