import ExoplanetPreview from "../ExoplanetPreview/ExoplanetPreview";
import exoplanets from "../../data/exoplanets.json"
import articles from "../../data/articles.json"
import facts from "../../data/facts.json"
import "./Explore.scss"
import ArticlePreview from "../ArticlePreview/ArticlePreview";


let indExoplanets = 3;
const amountExoplanets = 100;

let indArticles = 3;
const amountArticles = 19;

function showMoreExoplanets() {
    const container = document.getElementsByClassName("explore__exoplanets__exoplanets")[0];
    for (let i = indExoplanets; i < Math.min(indExoplanets + 3, amountExoplanets); i++) {
        if (i < container.children.length) {
            container.children[i].classList.remove("hidden");
        }
    }
    indExoplanets = Math.min(indExoplanets + 3, amountExoplanets);
    if (indExoplanets >= amountExoplanets) {
        const button = document.getElementsByClassName("explore__exoplanets__show-more")[0];
        button.remove();
    }  
}


function showMoreArticles() {
    const container = document.getElementsByClassName("explore__articles__articles")[0];
    for (let i = indArticles; i < Math.min(indArticles + 3, amountArticles); i++) {
        container.children[i].classList.remove("hidden");
    }
    indArticles = Math.min(indArticles + 3, amountArticles);
    if (indArticles >= amountArticles) {
        const button = document.getElementsByClassName("explore__articles__show-more")[0];
        button.remove();
    }  
}

function Explore() {
    const exoplanetsRows = [];
    let i = 0;
    for (const exoplanet of exoplanets) {
        
        
        const hidden = i >= 3;
        exoplanetsRows.push(<ExoplanetPreview  title={exoplanet.title} description={exoplanet.description}
                    radius={exoplanet.radius!} mass={exoplanet.mass!} orbital={exoplanet.orbital!}
                    detection={exoplanet.detection!} habitability={exoplanet.habitability!} 
                    starDistance={exoplanet.starDistance!} index={i} hidden={hidden}/>);
            
        i++;
        
    }

    const articlesRows = [];
    i = 0;
    for (const article of articles) {
        const hidden = i >= 3;
        articlesRows.push(<ArticlePreview title={article.title} 
                description={article.description} hidden={hidden}/>);
        i++;
    }

    const factsRows = [];
    for (const fact of facts) {
        factsRows.push(<li className="explore__facts__fact-list__fact">{fact.fact}</li>)
    }
    return (
        <div className="explore">
            <div className="explore__exoplanets container">
                <h1>Explore the exoplanets</h1>
                <div className="explore__exoplanets__exoplanets">
                    {exoplanetsRows}
                </div>
                <button onClick={showMoreExoplanets} className="explore__exoplanets__show-more show-more">Show more</button>
            </div>
            <div className="explore__articles container">
                <h1>Read our articles</h1>
                <div className="explore__articles__articles">
                    {articlesRows}
                </div>
                <button onClick={showMoreArticles} className="explore__articles__show-more show-more">Show more</button>
            </div>
            <div className="explore__facts container">
                <h1>Fun facts about exoplanets</h1>
                <ul className="explore__facts__fact-list">
                    {factsRows}
                </ul>
            </div>
        </div>
    );
}

export default Explore;