import { useNavigate } from "react-router-dom";
import "./ExoplanetPreview.scss"



function ExoplanetPreview({title, description, radius, mass, orbital,
         detection, habitability, starDistance, index, hidden} : 
            {title: string, description: string, radius: string, mass: string,
            orbital: string, detection: string, habitability: string,
            starDistance: string, index: number, hidden: boolean}) {

    let navigate = useNavigate(); 
    const routeChange = () =>{ 
        let path = `/exoplanet/?i=${index}`; 
        navigate(path);
    }

    const src = (index >= 50) ? "/" + ((index % 8 + 1).toString()) + ".jpg" : "/" + title.replaceAll(" ", "-").toLocaleLowerCase() + ".jpg";
    return (
        <div onClick={routeChange} className={"exoplanet-preview " + (hidden ? "hidden" : "")}>
            <div className="exoplanet-preview__img-container">
                <img className="exoplanet-preview__img-container__img" width="100%" height="100%" src={src}/>
            </div>
            <div className="exoplanet-preview__info-container">
                <h2 className="exoplanet-preview__info-container__title">{title}</h2>
                <span className="exoplanet-preview__info-container__description">{description}</span>
                <div className="exoplanet-preview__info-container__stats">
                    <p className="exoplanet-preview__info-container__stats__radius"><b>Radius:</b> {radius} x Earth</p>
                    <p className="exoplanet-preview__info-container__stats__mass"><b>Mass:</b> {mass} x Earth</p>
                    <p className="exoplanet-preview__info-container__stats__orbital"><b>Orbital period (years):</b> {orbital}</p>
                    <p className="exoplanet-preview__info-container__stats__detection"><b>Detection type:</b> {detection}</p>
                    <p className="exoplanet-preview__info-container__stats__habitability"><b>Habitability:</b> {habitability}</p>
                    <p className="exoplanet-preview__info-container__stats__star-distance"><b>Distance from the star (light years):</b> {starDistance}</p>
                </div>
            </div>
        </div>
    );
}

export default ExoplanetPreview;