import "./ArticlePreview.scss"

function ArticlePreview({title, description, hidden} : 
    {title: string, description: string, hidden: boolean}) {
    return (
        <div className={"article-preview " + (hidden ? "hidden" : "")}>
            <h2 className="article-preview__title">{title}</h2>
            <p className="article-preview__description">{description.split("\n").join("\n\n")}</p>
        </div>
    );
}

export default ArticlePreview;