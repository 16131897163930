import "./Navbar.scss"

function Navbar() {
    return (
        <div className="navbar container">
            <a href="/" className="navbar__title">Sirious</a>
            <nav className="navbar__nav">
                <a className="navbar__nav__element" href="/explore">EXPLORE</a>
                <a className="navbar__nav__element" href="/">ABOUT</a>
            </nav>
        </div>
    );
}

export default Navbar;