import { IframeHTMLAttributes } from "react";
import "./Home.scss"

function isIframe(obj: HTMLIFrameElement | Element): obj is HTMLIFrameElement {
    return obj.tagName === 'IFRAME';
}

function changeIframeHeight() {
    
    const video = document.getElementsByClassName('home__video')[0];
    if (isIframe(video)) {
        console.log("hey");
        video.height = (video.offsetWidth * (1080/1980)).toString();
    }
}

    


function Home() {
    //window.onresize = changeIframeHeight;
    //window.onload = changeIframeHeight;
    return (
        <div className="home">
            <div className="container">

                <h1 className="home__title">Our mission</h1>
                <p className="home__description">Our goal is to inspire curiosity and make the study of exoplanets accessible to everyone. Our app offers a gateway to the universe, inviting you to explore the most intriguing exoplanets discovered so far. With cutting-edge 3D models and real-time data such as size, mass, orbit, atmosphere, and potential for life, users can experience these distant worlds as if they were right in front of them. Whether you’re fascinated by the potential for life on other planets or curious about the unique atmospheres of gas giants, there’s something for everyone. The app’s intuitive design ensures that even complex concepts, like planetary mass or orbit trajectories, are made simple and exciting.</p>
                
                <h1 className="home__title">Target audiences</h1>
                <p className="home__description">From budding astronomers to seasoned space enthusiasts, our app caters to all knowledge levels. The content is designed to spark curiosity in young learners, encourage critical thinking in students, and provide detailed information for more advanced users. Interactive games (such as creating your own exoplanet) and puzzles challenge users to apply what they’ve learned, while interactive videos, historical discoveries, experiments, interesting facts and detection methods inspire further exploration. Teachers and educators will find this an invaluable tool for bringing the wonders of space into the classroom, fostering a love for science and discovery.</p>
                
                <h1 className="home__title">Join Our Cosmic Community!</h1>
                <p className="home__description">Reach a buzz in our growing space community! As part of us, you won’t just be a passive observer—you’ll become an active participant in the journey to understand the universe. Share your thoughts, theories, and creative ideas with like-minded individuals, and stay updated with weekly content that brings you the latest exoplanet discoveries. Join in and connect with other enthusiasts, navigating the universe together from discovering new exoplanets as knowledge about the wonders of space grows. Whether you're an accomplished astronomer or a fresh recruit into the study of astronomy, there is room for you here. Our app isn’t just about learning—it’s about inspiring collaboration, innovation, and a passion for astronomy that transcends borders and backgrounds. Let us now get this cosmic adventure airborne!</p>

                <h1 className="home__title">Are You Ready to Reach for the New World?</h1>
                <p className="home__description">Delve into our resources and let your imagination soar as you embark on your journey into the fascinating world of exoplanets. Join us in developing a new generation of astronomers who not only dream of the universe but also understand their special place within it. Together, we can instill the wonder of space exploration in young minds and prepare them to unlock the secrets of the cosmos!</p>
            </div>
        </div>
    );
}

export default Home;